import s from "./Supplies.module.css"
import photo1 from '../../assets/payback/left/8.jpg'
import photo2 from '../../assets/payback/left/3.jpg'
import photo3 from '../../assets/payback/left/7.webp'
import photo4 from '../../assets/payback/left/2.jpg'
import Item from "./Item/Item";

export const Supplies = () => {

    const suppliesData = [
        {
            city: "Чита", equipment: 'Обжимной станок BNT-68',
            description: [
                'Обжимное усилие - 490тс',
                'Диаметр обжимаемых шлангов - 6-51 (1/4"-2")',
                'Макс. обжим шланга - 51(2"), 4 слоя/оплётки',
                'Макс. раскрытие пресс-форм - +20 мм',
                'Количество пресс-форм - 10 комп.',
                'Источник питания - 380В, 50Гц (опция)',
                'Мощность электродвигателя - 3кВт',
                'Объём масляного бака - 65л',
                'Макс. давление в системе - 31,5 МПа',
            ],
            img: photo1,
            features: 'Предоставлена расширенная гарантия'
        },
        {
            city: "Красноярск", equipment: 'Окорочный станок BNT-S10',
            description: [
                'Диаметр внеш. окорки - 6-51 (1/4"-2")',
                'Диаметр внут. окорки - 6-51 (1/4"-2")',
                'Мощность электродвигателя - 0,75 кВт',
                'Скорость вращения - 480 об/мин',
                'Защитный короб - Включено',
                'Ящик для отходов - Включено',
                'Источник питания - 380В, 50Гц (опция)',
            ],
            img: photo2,
            features: 'Доставили в сжатые сроки, несмотря на закрытие таможни и эпидемию'
        },
        {
            city: "Армавир", equipment: 'Обжимной станок BNT-P32',
            description: [
                'Обжимное усилие - 500 тс',
                'Диаметр обжима (4 опл.) - 3-76 (1/8"-3")',
                'Диаметр обжима (6 опл.) - 3-63 (1/8"-2.1/2")',
                'Количество пресс-форм - 13 комп.',
                'ЖК дисплей - Включено',
                'Источник питания - 380В, 50Гц (опция)',
            ],
            img: photo3,
            features: 'Добавлено несколько кулачков и расходных материалов'
        },
        {
            city: "Хабаровск", equipment: 'Отрезной станок BNT-50С',
            description: [
                'Диаметр отрезаемых шлангов - 6-51 (1/4"-2") мм',
                'Мощность электродвигателя - 3 кВт',
                'Макс. внешний диаметр шлангов - 80 мм',
                'Количество дисковых ножей - 1 шт.',
                'Диаметр дискового ножа - 300 мм',
                'Источник питания - 380В, 50Гц',
            ],
            img: photo4,
            features: 'Проведено обучение работников заказчика'
        },
    ]

    return (
        <div className={s.supplies} id="supplies">

            <div className={s.secondTitle}>
                <h2>Примеры поставок оборудования</h2>
            </div>

            <div className={s.suppliesContent}>
                        {suppliesData.map((s, i) => <Item key={i}
                                                                       city={s.city}
                                                                       equipment={s.equipment}
                                                                       description={s.description}
                                                                       img={s.img}
                                                                       features={s.features}/>
                        )}
            </div>

        </div>
    )
}