// @ts-nocheck
import React, {Suspense} from "react";
import {Canvas} from "@react-three/fiber";
import {Sphere, MeshDistortMaterial} from "@react-three/drei";
import s from "./Home.module.css"
import SvgComponent from "./SvgLogo";
import Marquiz from "../../common/Form/Marquiz";

export const Home = () => {

    return (
        <div className={s.home}>
            {/*<div className={s.overlay}/>*/}

            <div className={s.homeContent}>

                <div className={s.homeLeft}>
                    <SvgComponent className={s.homeLogo}/>
                    <h1>
                        Станки от 100 000 руб. для производства РВД
                    </h1>
                    <h2 style={{fontWeight: 'normal'}}>
                        на комплект станков скидка до 70 000 руб
                    </h2>
                    <hr/>
                    <h3 style={{fontWeight: 'normal'}}>Закажите коммерческое предложение с ценой, под ваши цели:</h3>
                    <ul>
                        <li data-emoji="🔰">2-3 варианта линии изготовления РВД</li>
                        <li data-emoji="🔰">поставка из наличия и точные сроки</li>
                        <li data-emoji="🔰">гарантия до 3 лет</li>
                        <li data-emoji="🔰">сертификация ЕАС и СЕ</li>
                        <li data-emoji="🔰">понятная инструкция на русском языке в комплекте</li>
                        <li data-emoji="🎁">Акция на эксклюзивный станок 2 в 1: обжим + окорока!</li>
                    </ul>
                    <a href="#callbackwidget"><button>Получить коммерческое предложение</button></a>
                    {/*<Form/>*/}
                </div>

                <div className={s.homeRight}>
                    <Canvas className={s.canvas}>
                        <Suspense fallback={null}>

                            <ambientLight intensity={1.7}/>
                            <directionalLight position={[3, 2, 1]}/>
                            {
                                // @ts-ignore
                                <Sphere args={[1, 100, 200]} scale={1.7}>
                                    <MeshDistortMaterial
                                        color="rgb(81, 115, 36)"
                                        attach="material"
                                        distort={0.5}
                                        speed={0.5}
                                    />
                                </Sphere>
                            }
                        </Suspense>
                    </Canvas>
                    {/*<div className={s.canvas}></div>*/}
                </div>

            </div>

        </div>
    );
};