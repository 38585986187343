import s from "./Commercial.module.css"
import React from "react";
import {Form} from "../../common/Form/Form";

export const Commercial = () => {

    return (
        <div className={s.commercial} id="commercial">
            <div className={s.commercialContent}>

                <div className={s.commercialLeft}>

                    <div className={s.container}>
                        <div className={s.secondTitle}>
                            <h3 style={{textAlign: "center"}}>Закажите коммерческое предложение прямо сейчас</h3>
                            <p style={{textAlign: "center"}}>И получите скидку при подписании договора в течение<br/>2 недель до 70 000 руб, а также:</p>
                        </div>
                        <ul>
                            <li data-emoji="•">2-3 варианта линии изготовления РВД</li>
                            <li data-emoji="•">поставка из наличия и точные сроки</li>
                            <li data-emoji="•">гарантия до 3 лет</li>
                            <li data-emoji="•">сертификация ЕАС и СЕ</li>
                            <li data-emoji="•">понятная инструкция на русском языке в комплекте</li>
                            <li data-emoji="🎁">Акция на эксклюзивный станок 2 в 1: обжим + окорока!</li>
                        </ul>
                        <a href="#callbackwidget"><button>Получить коммерческое предложение</button></a>
                    </div>

                </div>

                {/*<div className={s.commercialRight}>
                    <Form/>
                </div>*/}

            </div>
        </div>
    )
}