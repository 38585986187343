import s from "./Selection.module.css"
import React from "react";
import {Form} from "../../common/Form/Form";

export const Selection = () => {

    return (
        <div className={s.selection} id="selection">
            <div className={s.selectionContent}>

                <div className={s.selectionLeft}>

                    <div className={s.container}>
                        <div className={s.secondTitle}>
                            <h3>Закажите подбор оборудования для ваших целей</h3>
                            <p style={{textAlign: "center"}}>И получите скидку при подписании договора<br/>в течение 2 недель до <b>70 000 руб</b>, а также:</p>
                        </div>
                        <ul>
                            <li data-emoji="•">2-3 варианта линии изготовления РВД</li>
                            <li data-emoji="•">поставка из наличия и точные сроки</li>
                            <li data-emoji="•">гарантия до 3 лет</li>
                            <li data-emoji="•">сертификация ЕАС и СЕ</li>
                            <li data-emoji="•">понятная инструкция на русском языке в комплекте</li>
                            <li data-emoji="🎁">Акция на эксклюзивный станок 2 в 1: обжим + окорока!</li>
                        </ul>
                        <div style={{textAlign: 'center'}}>
                            <a href="#callbackwidget"><button>Получить подбор оборудования</button></a>
                        </div>
                    </div>

                </div>

                {/*<div className={s.selectionRight}>
                    <a href="#callbackwidget"><button>Получить коммерческое предложение</button></a>
                    <Form/>
                </div>*/}

            </div>
        </div>
    )
}