import s from "./Scheme.module.css"

export const Scheme = () => {

    return (
        <div className={s.scheme} id="scheme">

            <div className={s.secondTitle}>
                <h2><span>Современные</span> компоненты оборудования</h2>
            </div>

            <div className={s.schemeContent}>

                <div className={s.schemeLeft}>
                    <div>
                        <p>Обжимной станок</p>
                        <ul>
                            <li data-emoji="•">Диаметр обжима точно регулируется под нужные фитинги</li>
                            <li data-emoji="•">Быстрая смена кулачков</li>
                            <li data-emoji="•">Ручной/автоматический и полуавтоматический режимы работы</li>
                            <li data-emoji="•">Расширенный набор из 13+ долговечных кулачков</li>
                        </ul>
                    </div>
                    <div>
                        <p>Окорочный станок</p>
                        <ul>
                            <li data-emoji="•">Зачистка симметричным лезвием внутри и снаружи</li>
                            <li data-emoji="•">Регулировка диаметра для режущих лезвий</li>
                            <li data-emoji="•">Позволяет добиться максимального прилегания муфты и фитинга<br/> к навивке РВД</li>
                            <li data-emoji="•">Качественная зачистка даёт больший срок службы изделия</li>
                        </ul>
                    </div>
                </div>

                <div className={s.middle}/>

                <div className={s.schemeRight}>
                    <div>
                        <p>Отрезной станок</p>
                        <ul>
                            <li data-emoji="•">Безупречный срез шланга</li>
                            <li data-emoji="•">Надежные, острые лезвия</li>
                            <li data-emoji="•">Чётко выверенный угол отреза 90°</li>
                            <li data-emoji="•">Отсутствие скопления пыли</li>
                            <li data-emoji="•">Легкая установка муфты благодаря ровному спилу</li>
                        </ul>
                    </div>
                    <div>
                        <p>Другие особенности наших станков</p>
                        <ul>
                            <li data-emoji="•">Компактные, легко интегрировать в цех</li>
                            <li data-emoji="•">Недорогие отрезные диски и доп. кулачки</li>
                            <li data-emoji="•">Электронная система управления на русском языке</li>
                            <li data-emoji="•">Возможна работа от генератора для большей мобильности</li>
                            <li data-emoji="•">Готовые линейки оборудования под ваши цели</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}